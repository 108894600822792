<template>
  <div class="settings">
    <div class="form-row">
      Name: <input type="text" v-model="meta.name" />
    </div>
    <div class="form-row">
      Resting Time: <input type="number" v-model="meta.restingTime" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'RoutineProperties',
  props: ['modelValue'],
  emits: ['update:modelValue'],
  computed: {
    meta: {
      get () {
        return this.modelValue
      },
      set () {
        this.$emit('update:modelValue', this.meta)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .settings{

    .exercise-item {
      list-style: none;
      border: 2px solid lightgray;
      margin:10px;

      img {
        margin:auto;
        display:block;
        max-width: 100px;
        max-height: 70px;
      }
    }

  }

</style>
