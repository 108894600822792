<template>
  <div class="organizer">
    <div @click="$emit('unselectMove')" class="drop-area">
      <draggable :list="routine" group="moves" class="drop-area list-group w-full" @change="log">
        <div class="exercise-item" v-for="(move, index) in routine" :key="move.id" @click="$event.stopPropagation(); $emit('selectMove', {move, index})">
          <img :src="move.thumbnail" />
          <label>{{move.name}}</label>
        </div>
      </draggable>
    </div>
    <slot></slot>
  </div>
</template>

<script>

import { VueDraggableNext } from 'vue-draggable-next'

export default {
  name: 'Organizer',
  props: ['modelValue'],
  emits: ['update:modelValue', 'selectMove', 'unselectMove'],
  components: {
    draggable: VueDraggableNext
  },
  data () {
    return {
      routine: []
    }
  },
  watch: {
    routine: {
      deep: true,
      handler (value) {
        this.$emit('update:modelValue', value.map((item, i) => ({ ...item, index: i })))
      }
    }
  },
  methods: {
    log (event) {
      const { moved, added } = event
      if (moved) console.log('moved', moved)
      if (added) console.log('added', added, added.element)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .organizer{
    position:relative;
    width:100%;

    ul {
      height:100%;
    }

    .drop-area {
      height:100%;
    }

    .list-group {
      display: flex;
      flex-wrap: wrap;
      align-content: center;
    }

    .exercise-item {
      list-style: none;
      border: 2px solid lightgray;
      margin:10px;
      width: 120px;
      height: 120px;
      cursor: pointer;

      img {
        margin:auto;
        display:block;
        max-width: 100px;
        max-height: 100px;
      }
    }

  }

</style>
