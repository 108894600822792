export default {
  categories: [
    {
      name: 'yoga',
      moves: [
        {
          name: 'Bird Dog',
          timed: true,
          time: 40,
          step: 10,
          thumbnail: 'images/yoga/bird_dog.png'
        },
        {
          name: 'Butterfly',
          timed: true,
          time: 40,
          step: 10,
          thumbnail: 'images/yoga/butterfly.png'
        },
        {
          name: 'Chair',
          timed: true,
          time: 40,
          step: 10,
          thumbnail: 'images/yoga/chair.png'
        },
        {
          name: 'Dancer',
          timed: true,
          time: 40,
          step: 10,
          thumbnail: 'images/yoga/dancer.png'
        },
        {
          name: 'Dog',
          timed: true,
          time: 40,
          step: 10,
          thumbnail: 'images/yoga/dog.png'
        },
        {
          name: 'Shoulder stand',
          timed: true,
          time: 40,
          step: 10,
          thumbnail: 'images/yoga/shoulder_stand.png'
        },
        {
          name: 'Tree',
          timed: true,
          time: 40,
          step: 10,
          thumbnail: 'images/yoga/tree.png'
        },
        {
          name: 'Warrior 1',
          timed: true,
          time: 40,
          step: 10,
          thumbnail: 'images/yoga/warrior_1.png'
        },
        {
          name: 'Warrior 2',
          timed: true,
          time: 40,
          step: 10,
          thumbnail: 'images/yoga/warrior_2.png'
        },
        {
          name: 'Wheel',
          timed: true,
          time: 40,
          step: 10,
          thumbnail: 'images/yoga/wheel.png'
        }
      ]
    },
    {
      name: 'intensive',
      moves: [
        {
          name: 'Abs',
          counted: true,
          timed: true,
          time: 40,
          count: 10,
          step: 2,
          thumbnail: 'images/intensive/abs.png'
        },
        {
          name: 'Plank',
          counted: true,
          timed: true,
          time: 40,
          count: 10,
          step: 2,
          thumbnail: 'images/intensive/plank.png'
        },
        {
          name: 'Push up',
          counted: true,
          timed: true,
          time: 40,
          count: 10,
          step: 2,
          thumbnail: 'images/intensive/push_up.png'
        },
        {
          name: 'Pull up',
          counted: true,
          time: 40,
          count: 10,
          step: 2,
          thumbnail: 'images/intensive/pull_up.png'
        },
        {
          name: 'Rope',
          counted: true,
          timed: true,
          time: 40,
          count: 10,
          step: 2,
          thumbnail: 'images/intensive/rope.png'
        },
        {
          name: 'Star',
          counted: true,
          timed: true,
          time: 40,
          count: 10,
          step: 2,
          thumbnail: 'images/intensive/star.png'
        }
      ]
    }
  ]
}
