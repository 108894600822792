<template>
  <div class="properties">
    <div class="form-row">
      <h3>{{item.name}}</h3>
    </div>
    <div class="form-row" v-if="item.timed">
      Time: <input type="number" v-model="item.time" />
    </div>
    <div class="form-row" v-if="item.counted">
      Count: <input type="number" v-model="item.count" />
    </div>
    <div class="form-row">
      <button @click="$emit('deleteMove')">DELETE</button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Properties',
  props: ['modelValue'],
  emits: ['update:modelValue', 'deleteMove'],
  computed: {
    item: {
      get () {
        return this.modelValue
      },
      set () {
        this.$emit('update:modelValue', this.item)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .move-properties{

    .exercise-item {
      list-style: none;
      border: 2px solid lightgray;
      margin:10px;

      img {
        margin:auto;
        display:block;
        max-width: 100px;
        max-height: 70px;
      }
    }

  }

</style>
