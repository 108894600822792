<template>
  <div class="collection">
    <div class="category" v-for="cat in categories" :key="cat.name">
      <h3>{{cat.name}}</h3>
      <draggable :list="cat.moves" :group="{ name: 'moves', pull: 'clone', put: false }" :sort="false" :clone="clone" class="dragArea list-group w-full">
        <div class="exercise-item" v-for="move in cat.moves" :key="move.name">
          <img :src="move.thumbnail" />
          <label>{{move.name}}</label>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>

import { VueDraggableNext } from 'vue-draggable-next'
import exerciseCategories from '@/config/exercises'

export default {
  name: 'Collection',
  components: {
    draggable: VueDraggableNext
  },
  data () {
    return {
      categories: exerciseCategories.categories
    }
  },
  methods: {
    clone (original) {
      return {
        ...original,
        id: this.uniqId()
      }
    },
    uniqId (prefix = '', random = false) {
      const sec = Date.now() * 1000 + Math.random() * 1000
      const id = sec.toString(16).replace(/\./g, '').padEnd(14, '0')
      return `${prefix}${id}${random ? `.${Math.trunc(Math.random() * 100000000)}` : ''}`
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .collection{
    padding-left:0;
    max-height:100%;
    overflow-y:scroll;

    .category {
      padding-inline-start: 0;
    }

    .exercise-item {
      list-style: none;
      border: 2px solid lightgray;
      margin:10px;

      img {
        margin:auto;
        display:block;
        max-width: 100px;
        max-height: 70px;
      }
    }

  }

</style>
