<template>
  <div class="menu" @click="$emit('unselectMove')">
    <span class="left-block">
      <button @click="$emit('toggleCollection')" title="Expand/Collapse Moves Collection"><i>ADD</i></button>
    </span>
    <span class="central-block">
      <select v-model="selectedRoutineName">
        <option v-for="routine in Object.keys(allRoutines)" :key="routine" :value="routine">{{routine}}</option>
      </select>
      <button @click="$emit('load', selectedRoutineName)" title="Load Routine"><i>LOAD</i></button>
      <button @click="$emit('save')" title="Save Routine"><i>SAVE</i></button>
      <button @click="$emit('play')" title="Play Routine"><i>PLAY</i></button>
    </span>
    <span class="right-block">
      <button @click="$emit('toggleSettings')" title="Expand/Collapse Settings"><i>SETTINGS</i></button>
    </span>
  </div>
</template>

<script>

export default {
  name: 'Menu',
  data () {
    return {
      selectedRoutineName: '',
      routine: []
    }
  },
  computed: {
    allRoutines () {
      return JSON.parse(localStorage.getItem('routines')) || {}
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .menu{
    display:flex;

    .left-block{
      flex: 0 0;
      order:1;
    }
    .central-block{
      flex: 1 0;
      order:2;
      text-align:center;
    }
    .right-block{
      flex: 0 0;
      order:3;
    }

  }

</style>
